import { ReactComponent as HomeIcon } from '@admin/assets/svg/home.svg';
import { ReactComponent as ControlIcon } from '@admin/assets/svg/control.svg';
import { ReactComponent as BurningIcon } from '@admin/assets/svg/burning.svg';
import { ReactComponent as DataIcon } from '@admin/assets/svg/data.svg';
import { ReactComponent as PermissionIcon } from '@admin/assets/svg/permission.svg';
import store from 'store';
import { redirect } from 'react-router-dom';

/**
 * 路由文件
 */
const WHITE_ROUTER = [
    {
        path: '/login',
        auth: false,
        lazy: () => import('@admin/views/Login')
    },
    {
        path: '*',
        auth: true,
        lazy: () => import('../Error'),
        loader: ({ params }) => {
            if (params['*'] === '') {
                const token = store.get('token');

                return token ? redirect('/home') : redirect('/login');
            }

            return null;
        }
    }
];

export const BASE_ROUTER = [
    {
        path: '/home',
        title: 'home',
        lazy: () => import('@admin/components/Layout'),
        icon: HomeIcon,
        children: [
            {
                index: true,
                lazy: () => import('@admin/views/HomeIndex')
            }
        ]
    },
    {
        path: '/remote',
        title: 'remote control',
        lazy: () => import('@admin/components/Layout'),
        icon: ControlIcon,
        permissions: ['100000'],
        children: [
            {
                path: 'transOrder',
                title: 'transparent command',
                lazy: async () => import('@admin/views/TransOrder'),
                permissions: ['100010']
            },
            {
                path: 'gridParams/:id',
                title: 'grid connection parameters',
                lazy: async () => import('@admin/views/GridParams'),
                params: {
                    id: 'gridParams'
                },
                permissions: ['100020']
            },
            {
                path: 'savingParams/:id',
                title: 'energy saving parameters',
                lazy: async () => import('@admin/views/GridParams'),
                params: {
                    id: 'savingParams'
                },
                permissions: ['100030']
            },
            {
                path: 'safety/:id',
                title: 'safety regulation parameters',
                lazy: async () => import('@admin/views/GridParams'),
                params: {
                    id: 'safety'
                },
                permissions: ['100040']
            },
            {
                path: 'device/:id',
                title: 'intelligent device parameters',
                lazy: async () => import('@admin/views/GridParams'),
                params: {
                    id: 'device'
                },
                permissions: ['100050']
            }
        ]
    },
    {
        path: '/burning',
        title: 'burner management',
        icon: BurningIcon,
        lazy: () => import('@admin/components/Layout'),
        permissions: ['200000'],
        children: [
            {
                path: 'firmware',
                title: 'firmware management',
                lazy: () => import('@admin/views/FirmwareManage'),
                permissions: ['200010']
            },
            {
                path: 'device',
                title: 'device burning',
                lazy: () => import('@admin/views/BurnManage'),
                permissions: ['200020']
            }
        ]
    },
    {
        path: '/data',
        title: 'data inquiry',
        icon: DataIcon,
        lazy: () => import('@admin/components/Layout'),
        permissions: ['300000'],
        children: [
            {
                path: 'template',
                title: 'query template',
                lazy: () => import('@admin/views/QueryTemplate'),
                permissions: ['300010']
            },
            {
                path: 'inverterBasic/:id',
                title: 'inverter data',
                lazy: () => import('@admin/views/InverterData'),
                params: {
                    id: 'INVERTER_INSTANT'
                },
                permissions: ['300020']
            },
            {
                path: 'inverterInstant/:id',
                title: 'inverter configuration data',
                lazy: () => import('@admin/views/InverterData'),
                params: {
                    id: 'INVERTER_BASIC'
                },
                permissions: ['300030']
            },
            {
                path: 'smartBasic/:id',
                title: 'intelligent device data',
                lazy: () => import('@admin/views/InverterData'),
                params: {
                    id: 'SMART_DEVICE_INSTANT'
                },
                permissions: ['300040']
            },
            {
                path: 'smartInstant/:id',
                title: 'smart device configuration data',
                lazy: () => import('@admin/views/InverterData'),
                params: {
                    id: 'SMART_DEVICE_BASIC'
                },
                permissions: ['300050']
            }
        ]
    },
    {
        path: '/permission',
        title: 'permission management',
        lazy: () => import('@admin/components/Layout'),
        icon: PermissionIcon,
        permissions: ['400000'],
        children: [
            {
                path: 'role',
                title: 'role management',
                lazy: () => import('@admin/views/RoleManagement'),
                permissions: ['400010']
            },
            {
                path: 'user',
                title: 'user management',
                lazy: () => import('@admin/views/Usermanagement'),
                permissions: ['400020']
            }
        ]
    }
];

export const routerRoot = [...BASE_ROUTER, ...WHITE_ROUTER];
